<template>
  <div class="home">
    <h2 class="title text-center">平台服务协议</h2>
    为了保护网络信息安全，保障公民、法人和其他组织的合法权益，维护国家安全和社会公共利益，根据国家法律法规以及全国人大常委会的相关规定，上海网班教育科技股份有限公司(以下简称“上海网班教育”)制定并按照本协议提供网络服务。用户应当充分阅读并同意本协议的全部条款并按照页面上的提示完成全部的注册程序(未成年人应与法定监护人共同完成)。用户在注册过程中点击“同意”按钮即表示用户完全接受本协议项下的全部条款，用户选择访问或使用上海网班教育有关服务，将视为同意接受本协议全部条款的约束。如果您不同意本协议或其中任何条款约定，请您立即停止注册或登录；您点击"同意"按钮后，本协议即构成对双方有约束力的法律文件。
    <br />一、定义
    <br />1、本站：在本协议中除非专门指明或声明，均指由上海网班教育科技股份有限公司负责运营的网培师网站；网培师所推出的新产品、新功能和新服务，均无条件的适用本协议。在未经声明或特别指出的情况下，网培师网站、相关的证书上链以及上海网班教育属于同一主体。
    <br />2、所有权以及相关的权利：在本服务协议中，除非专门指明或声明，均意味着：第一，所有权；第二，知识产权，包括但不限于著作权、专利权、商标权、商业秘密等；第三，除上述权利之外的对物、信息及其载体和表现形式的使用、利用、传播、复制、发行、编辑、修改、处分等权利。
    <br />
    3、用户：在本服务协议中，除非专门指明或声明，是指接受本服务协议的自然人、法人或其他组织。
    <br />
    4、个人信息：在本服务协议中，除非专门指明或声明，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别用户身份或者反映用户活动情况的各种信息。
    <br />
    5、网络服务：指本站基于互联网方式向用户提供的业务宣传、学习资料、考试服务、颁发证书等服务。
    <br />
    二、网络服务内容
    <br />1、本站向用户提供的网络服务包括： <br />
    （1）用户注册、登录、退出、修改密码、个人资料修改、证书印刷信息上传；
    <br />
    （2）服务缴费、订单查询、发票申请；
    <br />
    （3）作品上传、成绩查询；
    <br />
    （4）在线模拟考试、在线评测服务；
    <br />
    （5）证书查询、证书颁发、证书上链；<br />
    （6）本站为用户提供的其他服务。
    <br />2、本站有权根据情况变化调整网络服务的具体内容，本站提供的各类服务随时都有可能发生变动，对此本网站不作特别通知，也不对网络服务的提供或不提供作出承诺。
    <br />
    三、权利声明 <br />
    1、任何本站所发布的信息、规则或服务，除以下情况之外，其所有权及相关的权利均独占性地属于网培师平台：
    <br />
    （1）依据中华人民共和国的相关法律规定该信息或服务，不享有所有权以及与此相关的权利的；
    <br />2、用户只能在本站和内容提供者授权下才能使用由本站享有权利的内容，且无权擅自复制、修改这些内容，或创造与内容有关的派生产品。
    <br />3、本网站有权力将用户的资料推荐给招聘岗位，为其推荐工作。
    <br />
    四、用户账号注册与管理
    <br />1、用户在完成注册或登录程序实际使用本网站提供服务时，应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。若用户不具备前述主体资格，则用户及用户的监护人应当依照法律规定承担因此而导致的一切后果，本网站有权注销或永久冻结您的账户。
    <br />
    2、您可以通过本网站进行注册，获得在本站的帐号。在完成注册、激活或验证流程时，您应当按照相应页面的提示提供您的身份信息及资料，您应保证提供的身份信息及资料真实、及时、完整和准确。
    <br />3、您在本站设置的用户名不得违反国家法律法规关于互联网用户账号名称的管理规定，否则本站将有权回收您的用户名。用户名的回收不会影响您以邮箱、手机号码登录本站并使用本站服务。
    <br />
    4、您在本站的账号、密码是您在本站的唯一身份权证。为享受本网站提供的网络服务，您须按照对应的服务要求键入正确的用户账号及密码。
    <br />
    5、您应保管好自己在本站的账号、密码，避免使用过于简单的密码。您在服务使用过程中可以根据本站规定更改密码。非经本站事先同意，账号和密码不得出借、转让、继承或者赠与。非因本站原因导致您的账号、密码遗失、遗忘或被他人窃取的，本站不承担责任。如果您发现自己的个人信息泄密，尤其是本站账户及密码发生泄露，请您立即联络本站。
    <br />
    6、个人用户在支付费用时，请在网培师平台进行支付；一切不明链接生成的支付码，造成用户损失的，则有用户自行承担，本平台不承担任何后果。
    <br />
    7、本网站将采取相应的措施来保证用户的帐户和交易安全，但本网站不保证其绝对安全。
    <br />
    五、免责声明
    <br />1、您知悉及同意，网培师平台不对您可能通过网培师平台接触的到任何内容负责，您同意自担风险使用本服务。
    <br />2、基于互联网的开放性属性，您知悉将图片、信息或任何资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，您必须充分意识到此类风险的存在；您明确同意使用本网站及本协议服务所存在的风险将完全由您自己承担；因第三方侵权所产生的责任和损失将由您向第三方追索，网培师平台无须就此向您承担任何责任。
    <br />
    3、您知悉并理解本服务使用第三方供应商或托管合作伙伴提供必要的硬件、软件、网络、存储以及相关的技术来运行服务，您同意遵守该等第三方的任何业务规则，并同意因该等第三方硬件、软件、网络、存储以及相关技术所引发的任何故障、意外、责任或风险将由您与该等第三方解决，网培师平台不就此作出任何承诺或保证，亦不承担任何责任。
    <br />
    4、您不得以任何方式明示或暗示您或非网培师平台所有及运营的任何网站或服务与网培师平台有任何关联。
    <br />
    5、不论在何种情况下，网培师平台均不对由于Internet连接故障，通讯或其他系统的故障，黑客攻击、病毒、电力故障，罢工，劳动争议，暴乱，起义，骚乱，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令，第三方的不作为或任何网培师平台不能合理控制的原因而造成的本平台不能访问、信息及数据的延误、停滞或错误，不能提供或延迟提供本服务而承担责任。
    <br />
    6、网培师平台将采取现有技术水平项下合理可行的措施努力保护用户帐号和数据隐私，但网培师平台不能确保用户的全部私人通讯及其他资料不会通过本隐私规则中未列明的途径泄露出去，您知悉并同意承担上述风险，并同意网培师平台无须就此承担任何责任。
    <br />
    六、网络服务的停止 <br />
    1、在以下情形下，本站有权单方并在不做出任何通知的情况下立即停止对用户提供网络服务:
    <br />（1）若该用户是通过手机号码登陆的，当该用户所对应的手机号码停机或销号后；
    <br />（2）用户违反本协议规定使用网络服务；
    <br />（3）用户提供的注册资料不真实； <br />（4）用户账户被删除后；
    <br />
    2、用户对本协议后续的修改有异议，或对本站的服务不满，可以行使如下权利：
    <br />（1）停止使用本站的网络服务； <br />（2）通告本站停止对该用户的服务；
    <br />3、用户服务停止后，本站没有义务向该用户或第三方传送任何未处理的信息或未完成的服务，亦无需对该用户或第三方负责。但本协议的终止并不意味着终止前所发生的未完成用户指令的撤销，也不能消除因终止前的交易所带来的法律后果。
    <br />
    七、第三方链接
    <br />
    1、从本站链接至本站以外的网站：本站提供跳转至国际互联网上的其它页面或网站的链接，仅为协助用户浏览和参考之用。网培师致力于挑选声誉良好的网站和资料来源，以方便用户。然而，除非网培师已经明确声明与该第三方有合作关系，提供链接至此第三方网站或网页，并不视为教育链同意、推荐、认可、保证或推介任何第三方或在第三方网站上所提供的任何服务、产品，亦不可视为网培师与该第三方及其网站有任何形式的合作。
    <br />2、从本站链接至评测系统：本网站链接的评测系统运营主体同为上海网班教育科技股份有限公司，不存在第三方运营。该评测系统所提供的产品和服务同样提供给网培师网站已付费用户使用。
    <br />3、从其他网站链接至本站：若您想在第三方的网站以任何形式建立链接至本站，必须先取得本网站的书面同意,本网站有权决定是否核准建立此链接。凡从第三方网站建立任何链接至本站，本网站不负责该链接的建立与设置。依此建立的链接，并不构成本网站与该第三方网站有任何形式的合作，亦不构成本网站对该第三方网站的认同。如因该链接而产生或导致的任何本网站动保留随时撤销核准的权利，并有权要求清除任何指向本站的链接。
    <br />八、通告
    <br />所有发给用户的通告都可通过页面的公告或短信或常规的信件传送。服务条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。
    <br />
    九、服务条款修改<br />
    本站有权根据法律法规变化和网站运营需要，对本协议条款不时地进行修改。本协议一旦发生变动，本站将通过公告形式通告用户，修改后的协议一经通告即生效。用户如果不同意所改动的内容，应主动停止使用本站提供的网络服务。如果用户继续享用本站网络服务，则视为接受服务条款的变动。
    <br />
    十、法律 <br />
    1、本协议条款适用中华人民共和国的法律法规。如协议条款与中华人民共和国法律相抵触，则依照法律的规定重新解释该服务条款，但不影响其它条款的法律效力和影响。
    <br />
    2、本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律并受中华人民共和国法院管辖。
    <br />
    3、用户与本站一致同意：
    <br />
    （1）双方应持平等、友好、争取合理解决问题的态度；
    <br />
    （2）发生纠纷时首先以协商方式解决，如协商未果，任何一方应向中国上海网班教育科技股份有限公司所在地人民法院提起诉讼。
    <br />
    十一、其他
    <br />1、本网站未行使本协议的任何权利或规定，并不构成对前述权利之放弃。
    <br />2、如本协议中的任何条款完全或部分无效，本协议的其余条款仍有效并且有约束力。
    <br />3、本协议内容包括协议正文及本站上发布的声明和规则，包括但不限于报考指南、业务说明、个人信息保护政策等。所有声明、政策和规则等与协议正文具有同等法律效力，共同构成完整的网站服务协议。
    <br />
    <br />
    <p class="read text-center" @click="toRegist">我已阅读,去注册>></p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {},
  data() {
    return {}
  },
  methods: {
    toRegist() {
      this.$router.push({ name: 'Regist' })
    },
  },
}
</script>
<style lang="less" scoped>
.home {
  font-weight: normal;
  font-size: 14px;
  color: #666;
  width: 60%;
  min-width: 1200px;
  margin: 50px auto;
  position: relative;
  line-height: 24px;
  .title {
    font-size: 20px;
    padding: 20px 0;
    font-weight: bolder;
    color: #333;
  }
  .read {
    color: red;
    cursor: pointer;
  }
}
</style>
